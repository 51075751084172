export const convertLbtoKg = (lb: number) =>
  parseFloat((lb * 0.4535924).toFixed(2));

export const convertKgtoLb = (kg: number) => Math.round(kg * 2.20462262185);

export const convertHeightToCm = (ft: number, inch: number) =>
  Number(String((ft * 12 + inch) * 2.54));

export const convertCmToInch = (cm: number) => cm * 0.393700787;

export const convertCmToFeet = (cm: number) => cm / 30.48;

export const getHeightImperial = (height: string | number | boolean) => {
  const heightSplitted = convertCmToFeet(height as number)
    .toFixed(2)
    .split('.');
  return `${heightSplitted[0]}′${Math.round(Number(heightSplitted[1]) / 10)}″`;
};

export const oneTwo = (
  number: number,
  one: string,
  two: string,
  returnNumber = false,
) => {
  if (returnNumber) {
    if (number === 1) return one;
    return `${number} ${two}`;
  }
  if (number === 1) return one;
  return two;
};
