import React from 'react';

import { QuizAnswer } from 'state/user/types';
import { Question, QuestionTypes } from 'state/funnel/types';

import { UserDataOption } from '../options/UserDataOption';
import { UserNameOption } from '../options/UserNameOption';
import { MultipleOption } from '../options/MultipleOption';
import { SingleOption } from '../options/SingleOption';
import { Intermission } from '../options/Intermission';

export interface QuizCallbacks {
  question: Question;
  onSelect: (answer: QuizAnswer) => void;
  isGenderQuestion?: boolean;
}

const questions = {
  [QuestionTypes.single]: SingleOption,
  [QuestionTypes.multiple]: MultipleOption,
  [QuestionTypes.user_data]: UserDataOption,
  [QuestionTypes.user_name]: UserNameOption,
  [QuestionTypes.intermission]: Intermission,
};

export const AskQuestion: React.FC<QuizCallbacks> = ({
  question,
  onSelect,
  isGenderQuestion,
}) => {
  const Question = questions[question?.type];

  if (!Question) {
    return <div>Question type is not found</div>; // Return error alert
  }

  return (
    <Question
      onSelect={onSelect}
      question={question}
      isGenderQuestion={isGenderQuestion}
    />
  );
};
