import React from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { Image } from 'components';

import { QuizCallbacks } from '../sections/AskQuestion';

export const Intermission: React.FC<QuizCallbacks> = ({ question }) => {
  const { isMobile } = useQuery();

  return question?.custom?.image ? (
    <Container>
      <IntermissionImage
        src={`${question.custom.image}${isMobile ? '_mobile' : ''}`}
      />
    </Container>
  ) : null;
};
const Container = styled.div`
  width: 30rem;
`;

const IntermissionImage = styled(Image)`
  max-width: 30.75rem;
  width: 100%;

  @media ${mobile} {
    margin-bottom: 2.5rem;
  }
`;
