import 'react-circular-progressbar/dist/styles.css';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import { almostWhite, black, blue, primary } from 'styles/colors';
import { Caption, Container, Logo, Svg, TextWrapper } from 'components';

interface Props {
  afterLoadingEnds?: () => void;
}

export const LoadingScreen: React.FC<Props> = ({ afterLoadingEnds }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(value => {
        if (value >= 100) {
          clearInterval(interval);
          afterLoadingEnds?.();
          return value;
        }
        return value + 2;
      });
    }, 65);

    return () => clearInterval(interval);
  }, []);

  return (
    <Background>
      <Container>
        <TextWrapper
          textAlign="center"
          margin="0 auto"
          height="calc(100vh - 2rem)"
        >
          <Logo />
          <Loader>
            <Progressbar
              id="quiz__progress-bar"
              data-testid="quiz__progress-bar"
            >
              <CircularProgressbar
                value={value}
                strokeWidth={2}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathColor: primary,
                  textColor: black,
                  trailColor: 'rgba(242, 97, 79, 0.2)',
                })}
              />
              <LoaderTextWrapper>
                <Svg
                  src="icons/leaves_1"
                  width="52"
                  height="36"
                  viewBox="0 0 52 36"
                  fill="none"
                />
                <Caption textAlign="center" color={blue}>
                  Analyzing
                  <br />
                  your results...
                </Caption>
              </LoaderTextWrapper>
            </Progressbar>
          </Loader>
        </TextWrapper>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  min-height: 100vh;
  padding-top: 1.25rem;
  background: ${almostWhite};
`;
const Progressbar = styled.div`
  position: relative;
  width: 17.5rem;
  margin: 0 auto 2rem auto;
`;
const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const LoaderTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
