import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { blue, blue50, white } from 'styles/colors';
import { convertHeightToCm, convertLbtoKg } from 'utils/metrics';
import { Circle, FlexWrapper, Input, OutlineButton, Tiny } from 'components';

import { QuizCallbacks } from '../sections/AskQuestion';
import { fadeIn } from 'styles/animations';

interface IData {
  [key: string]: string;
}

const DEFAULT_VALUES = {
  heightCm: '',
  heightFt: '',
  heightIn: '',
  weight: '',
};

export const UserDataOption: React.FC<QuizCallbacks> = ({ onSelect }) => {
  const [isMetric, setIsMetric] = useState(false);
  const { isMobile } = useQuery();
  const [data, setData] = useState<IData>(DEFAULT_VALUES);
  const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    const d: IData = { ...data };
    console.log(`data`, data);

    e.preventDefault();

    if (!isMetric) {
      d['weight'] = String(convertLbtoKg(parseInt(d['weight'])));
    }

    d['height'] = isMetric
      ? data['heightCm']
      : String(
          convertHeightToCm(parseInt(d['heightFt']), parseInt(d['heightIn'])),
        );

    delete d['heightCm'];
    delete d['heightFt'];
    delete d['heightIn'];

    setData(d);
    onSelect({
      ...d,
      pureHeight: isMetric
        ? data.heightCm
        : `${data.heightFt}"${data.heightIn}`,
      pureWeight: data.weight,
      isMetric,
    });
  };
  const onTextUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name.includes('height')) {
      if (isMetric) {
        setData({ ...data, heightCm: value });
      } else if (name === 'heightFt') {
        setData({ ...data, heightFt: value });
      } else {
        setData({ ...data, heightIn: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };

  useEffect(() => setData(DEFAULT_VALUES), [isMetric]);

  return (
    <>
      <FlexWrapper
        alignItems="center"
        justifyContent="center"
        margin="0 auto 2.375rem"
      >
        <FirstTabWrapper>
          <Tiny
            color={isMetric ? blue50 : blue}
            onClick={() => setIsMetric(false)}
            cursor="pointer"
          >
            IMPERIAL
          </Tiny>
          <Circle
            size={0.3125}
            color={isMetric ? 'transparent' : blue}
            margin="0.5rem auto 0"
          />
        </FirstTabWrapper>
        <div>
          <Tiny
            color={isMetric ? blue : blue50}
            onClick={() => setIsMetric(true)}
            cursor="pointer"
          >
            METRIC
          </Tiny>
          <Circle
            size={0.3125}
            color={isMetric ? blue : 'transparent'}
            margin="0.5rem auto 0"
          />
        </div>
      </FlexWrapper>
      <Form onSubmit={onSubmit}>
        <Input
          onChange={onTextUpdate}
          placeholder="Weight"
          type="number"
          name="weight"
          inputBackgroundColor={white}
          value={String(data.weight)}
          label={isMetric ? 'kg' : 'lb'}
          min={isMetric ? 40 : 89}
          max={isMetric ? 150 : 330}
        />
        {isMetric ? (
          <Input
            onChange={onTextUpdate}
            placeholder="Height"
            type="number"
            name="height"
            label="cm"
            inputBackgroundColor={white}
            value={String(data.heightCm)}
            min={150}
            max={220}
            height="50px"
            margin="0"
          />
        ) : (
          <FlexWrapper>
            <Input
              onChange={onTextUpdate}
              className="inline__left"
              placeholder="Height"
              inputBackgroundColor={white}
              value={String(data.heightFt)}
              type="number"
              name="heightFt"
              label="ft"
              min={5}
              max={7}
              margin="0"
            />
            <Input
              onChange={onTextUpdate}
              className="inline__right"
              placeholder=""
              inputBackgroundColor={white}
              value={String(data.heightIn)}
              type="number"
              name="heightIn"
              label="in"
              min={0}
              max={11}
              margin="0"
            />
          </FlexWrapper>
        )}
        <OutlineButton
          type="submit"
          maxWidth={isMobile ? '100%' : ''}
          margin="2.5rem auto 1.375rem"
        >
          NEXT
        </OutlineButton>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 24.125rem;
  margin: auto;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: none;
  }
`;
const FirstTabWrapper = styled.div`
  margin-right: 1.875rem;
`;
