import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Condition, QuizAnswer } from 'state/user/types';
import styled, { css } from 'styled-components';
import { navigate, useLocation } from '@reach/router';

import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import { Analytics } from 'apis/Analytics';
import { mobile, useQuery, xsMobile } from 'styles/breakpoints';
import { almostWhite, blue, blue70 } from 'styles/colors';
import { updateQuizAnswers } from 'state/user/effects';
import {
  FlexWrapper,
  H2,
  H3,
  Logo,
  PrimaryButton,
  ProgressBar,
  Regular,
  Seo,
  Small,
  Svg,
  Tiny,
} from 'components';

import { AskQuestion } from './sections/AskQuestion';
import { LoadingScreen } from './sections/LoadingScreen';

const Quiz = memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isMobile } = useQuery();
  const { goToLanding, goToSummary } = useRouter();
  const [step, setStep] = useState<number>(
    location.search.split('?gender=').length === 2 ? 1 : 0,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const questions = useSelector((s: AppState) => s.funnel.questions);
  const question = questions[step];
  const isIntermission = question?.type === 'intermission';
  const hasExplanation = !!question?.custom?.explanationText;
  const { quiz_answers } = useSelector((s: AppState) => s.user);
  const query = new URLSearchParams(useLocation().search);
  const isGenderQuestion = question?.['key'] === 'gender';

  const goToQuestion = (number: number, replace = false) => {
    navigate(`?step=${number}`, { replace });
  };

  useEffect(() => {
    const parsed = query.get('step') || '0';
    setStep(parseInt(parsed, 10));
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [query]);

  useEffect(() => {
    // gender has already been set through search params
    if (isGenderQuestion && query.get('gender')) {
      goToQuestion(step + 1, true);
    }
  }, [question, query]);

  const handleBackButton = () => {
    let prev = step - 1;
    // Find first question that fits conditions backwards
    while (prev >= 0) {
      if (!questions[prev].conditions?.length) {
        break;
      }

      if (matchConditions(questions[prev].conditions || [], quiz_answers)) {
        break;
      }

      prev--;
    }

    if (prev === -1) {
      goToLanding();
      return;
    }

    goToQuestion(prev);
  };

  const matchConditions = (conditions: Condition[], answers: QuizAnswer) =>
    conditions.every(
      c =>
        (c.mustInclude !== undefined &&
          String(answers?.[c.key]).includes(String(c.mustInclude))) ||
        (c.cantBe !== undefined &&
          String(answers?.[c.key]) !== String(c.cantBe)) ||
        (c.selectionsLessThen &&
          String(answers?.[c.key]).split('|').length <= c.selectionsLessThen) ||
        (c.selectionsMoreThen &&
          String(answers?.[c.key]).split('|').length > c.selectionsMoreThen),
    );

  const onSelect = (value?: QuizAnswer) => {
    if (value) {
      dispatch(updateQuizAnswers(value));
      Analytics.trackQuizAnswer(question['label'], String(value['label']));
      delete value['label'];
    }

    const nextQuestion = questions.findIndex((q, index) => {
      if (index <= step) return false;
      if (!q.conditions?.length) return true;

      return matchConditions(q.conditions, {
        ...quiz_answers,
        ...value,
      });
    });

    if (nextQuestion === -1) {
      setIsLoading(true);
    }

    goToQuestion(nextQuestion);
  };

  const backButton = (
    <BackButtonWrapper
      alignItems="center"
      justifyContent="center"
      onClick={handleBackButton}
    >
      <Svg
        src="icons/arrow_left"
        width="11"
        height="9"
        viewBox="0 0 11 9"
        fill="none"
      />
      <Tiny color={blue} margin="0 0 0 0.25rem">
        BACK
      </Tiny>
    </BackButtonWrapper>
  );

  if (isLoading) {
    return <LoadingScreen afterLoadingEnds={goToSummary} />;
  }

  if (!question) {
    return null;
  }

  return (
    <>
      <Seo
        title="Take the heartburn relief quiz | Pulsio"
        description="Take the Fireflux heartburn relief quiz, get your condition summary and a treatment kit built just for you."
      />
      <Background
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="column"
      >
        <FlexWrapper flexDirection="column" alignItems="center">
          <ProgressBar step={step + 1} totalSteps={questions.length} />
          <Logo notClickable />
        </FlexWrapper>
        <Layout
          isIntermission={isIntermission}
          hasExplanation={hasExplanation}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <Content isIntermission={isIntermission}>
            <LabelWrapper
              flexDirection="column"
              justifyContent="center"
              alignItems={isIntermission && !isMobile ? 'flex-start' : 'center'}
              isIntermission={isIntermission}
            >
              <Svg
                src="icons/leaves_2"
                width="52"
                height="36"
                viewBox="0 0 52 36"
                fill="none"
              />
              {isIntermission ? (
                <H2
                  margin="0.625rem 2rem 1.25rem 0"
                  padding={isMobile ? '0 1rem' : '0'}
                  textAlign={isMobile ? 'center' : 'left'}
                  mobileLineHeight="1.75rem"
                  mobileFontSize="1.5rem"
                  color={blue}
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                />
              ) : (
                <H3
                  className="fadeIn"
                  margin="0.625rem 0 2rem 0"
                  textAlign="center"
                  color={blue}
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                />
              )}
              {isIntermission && !!question?.custom?.texts?.length ? (
                <>
                  {question?.custom?.texts.map(text => (
                    <Regular
                      key={text}
                      color={blue70}
                      padding="0 0 2rem"
                      textAlign={isMobile ? 'center' : null}
                    >
                      {text}
                    </Regular>
                  ))}
                  <ButtonWrapper>
                    <PrimaryButton
                      maxWidth={isMobile ? '100%' : '11.125rem'}
                      margin="0 0 1.375rem"
                      onClick={() => onSelect(undefined)}
                    >
                      GOT IT!
                    </PrimaryButton>
                    {backButton}
                  </ButtonWrapper>
                </>
              ) : null}
            </LabelWrapper>
            <AskQuestion
              question={question}
              onSelect={onSelect}
              isGenderQuestion={isGenderQuestion}
            />
            {!isIntermission && step > 0 ? backButton : null}
          </Content>
          {question?.custom?.explanationText ? (
            <ExplanationTextWrapper>
              <Small fontWeight="700" color={blue}>
                {question?.custom?.explanationTitle &&
                  `${question?.custom?.explanationTitle}?`}
              </Small>
              <Small color={blue}>{question.custom.explanationText}</Small>
            </ExplanationTextWrapper>
          ) : (
            <div />
          )}
        </Layout>
      </Background>
    </>
  );
});

Quiz.displayName = 'Quiz';

export default Quiz;

const Background = styled(FlexWrapper)`
  min-height: 100vh;
  background: ${almostWhite};
`;
const Layout = styled(FlexWrapper)<{
  isIntermission?: boolean;
  hasExplanation?: boolean;
}>`
  padding: ${props => (props.isIntermission ? '4.188rem 0' : '5% 0')};
  padding-bottom: ${props => (props.hasExplanation ? '12%' : '2rem')};

  @media ${mobile} {
    height: ${props => (props.isIntermission ? 'unset' : '100%')};
    padding-top: 1rem;
    padding-bottom: ${props => (props.isIntermission ? '0' : '8.75rem')};
  }
`;
const Content = styled.div<{ isIntermission?: boolean }>`
  width: 100%;

  ${({ isIntermission }) =>
    isIntermission &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 63.25rem;

      @media ${mobile} {
        flex-direction: column-reverse;
      }
    `}

  @media ${mobile} {
    padding: 0 1.5rem 2rem;
  }
`;
const LabelWrapper = styled(FlexWrapper)<{ isIntermission?: boolean }>`
  max-width: ${props => (props.isIntermission ? 27.125 : 37.125)}rem;
  width: 100%;
  margin: 0 auto;
  @media ${mobile} {
    padding-top: ${props => (props.isIntermission ? '2rem' : '0')};
  }
`;
const BackButtonWrapper = styled(FlexWrapper)`
  cursor: pointer;
`;
const ExplanationTextWrapper = styled.div`
  align-self: flex-start;
  max-width: 33rem;
  width: 100%;
  padding: 3.75rem;
  position: absolute;
  bottom: 0;

  @media ${mobile} {
    padding: 2.5rem 1.5rem 3.125rem;
  }

  @media ${xsMobile} {
    background: ${almostWhite};
    bottom: -4rem;
  }
`;
const ButtonWrapper = styled.div`
  @media ${mobile} {
    width: 100%;
  }
`;
