import React, { useState } from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { blue50, white } from 'styles/colors';
import { Input, OutlineButton, Small } from 'components';

import { QuizCallbacks } from '../sections/AskQuestion';
import { Link } from 'gatsby';
import { fadeIn } from 'styles/animations';

export const UserNameOption: React.FC<QuizCallbacks> = ({ onSelect }) => {
  const [name, setName] = useState<string>('');
  const { isMobile } = useQuery();

  return (
    <Form onSubmit={() => onSelect({ name })}>
      <Input
        name="name"
        placeholder="Type here..."
        value={name}
        inputBackgroundColor={white}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setName(e.target.value)
        }
      />
      <Small textAlign="center" color={blue50}>
        Your name will only be used to personalize your treatment experience and
        diagnosis. We respect your personal data.
      </Small>
      <OutlineButton
        type="submit"
        maxWidth={isMobile ? '100%' : ''}
        margin="2rem auto 1.375rem"
      >
        NEXT
      </OutlineButton>
    </Form>
  );
};

const Form = styled.form`
  max-width: 24.125rem;
  margin: auto;
  opacity: 0;
  animation: ${fadeIn} 0.2s ease-in forwards;
  animation-delay: 0.1s;

  @media ${mobile} {
    max-width: none;
  }
`;
const Flex = styled.div`
  display: flex;
`;
const FirstTabWrapper = styled.div`
  margin-right: 1.875rem;
`;
