import React, { useState } from 'react';
import styled from 'styled-components';

import { Option } from 'state/funnel/types';
import { mobile, useQuery } from 'styles/breakpoints';
import { FlexWrapper, OptionCard, OutlineButton } from 'components';

import { QuizCallbacks } from '../sections/AskQuestion';

export const SingleOption: React.FC<QuizCallbacks> = ({
  onSelect,
  question,
  isGenderQuestion,
}) => {
  const { isMobile } = useQuery();
  const options = question.options || [];
  const isBig = !!question?.custom?.isBig;
  const verticalAlignAndRadio = question?.custom?.verticalAlign ?? false;
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const disabled = !selectedAnswer;

  const handleSelect = (option: Option) => {
    const { value, label } = option;
    verticalAlignAndRadio
      ? setSelectedAnswer(value)
      : onSelect({ [question.key]: value, label: label });
  };

  const onSubmit = () => {
    if (!disabled) {
      if (question.key) {
        onSelect({ [question.key]: selectedAnswer, label: selectedAnswer });
      }
    }
    setSelectedAnswer('');
  };

  return (
    <>
      <OptionsWrapper
        isBig={isBig}
        alignItems="center"
        justifyContent="space-around"
        flexDirection={isMobile || verticalAlignAndRadio ? 'column' : 'row'}
        isGenderQuestion={isGenderQuestion}
      >
        {options.map((option: Option, index: number) => (
          <OptionCard
            key={index}
            {...option}
            isBig={isBig}
            onClick={() => handleSelect(option)}
            isRadio={verticalAlignAndRadio}
            isSelected={selectedAnswer === option.value}
            isGenderQuestion={isGenderQuestion}
          />
        ))}
      </OptionsWrapper>
      {verticalAlignAndRadio && (
        <OutlineButton
          maxWidth={isMobile ? '100%' : '11.125rem'}
          margin="0 auto 1.375rem"
          disabled={disabled}
          onClick={onSubmit}
        >
          NEXT
        </OutlineButton>
      )}
    </>
  );
};

const OptionsWrapper = styled(FlexWrapper)<{
  isBig?: boolean;
  isGenderQuestion?: boolean;
}>`
  max-width: ${props =>
    props.isBig ? 76.125 : props.isGenderQuestion ? 25.5 : 37.125}rem;
  width: 100%;
  margin: 0 auto 2.125rem auto;

  @media ${mobile} {
    margin: 0 auto 1.875rem auto;
  }
`;
