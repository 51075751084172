import { Condition } from 'state/user/types';

export enum QuestionTypes {
  'single' = 'single',
  'multiple' = 'multiple',
  'user_data' = 'user_data',
  'user_name' = 'user_name',
  'intermission' = 'intermission',
}

export type Experiments = Record<string, boolean>;
export interface Option {
  label: string;
  value: string;
  custom?: {
    icon?: 'alcohol_glass' | 'cigarette' | 'stress' | 'none';
    iconWidth?: number;
    iconHeight?: number;
    iconSize?: string;
    sublabel?: string;
    deselectAll?: boolean;
  };
}
export interface Question {
  type: QuestionTypes;
  key: string;
  label: string;
  text?: string;
  image?: string;
  author?: string;
  noTitle?: string;
  quoteimg?: string;
  show?: 'no' | 'yes';
  imageMobile?: string;
  options?: Option[];
  conditions: Condition[];
  custom?: {
    isBig?: boolean;
    image?: string;
    explanationText?: string;
    explanationTitle?: string;
    texts?: Array<string>;
    verticalAlign?: boolean;
  };
}
export interface Product {
  key:
    | 'fireflux-1-subscription'
    | 'fireflux-3-subscription'
    | 'fireflux-6-subscription'
    | 'fireflux-1-one-time'
    | 'fireflux-3-one-time'
    | 'fireflux-6-one-time';
  sku: string;
  name: string;
  is_upsell: boolean;
  image: string | null;
  is_subscription: boolean;
  description: string | null;
  type: 'physical' | 'digital';
  images: string[];
  prices: Price[] | [];
}
export interface Price {
  price: number;
  discount: number;
  currency_id: string;
  final_price: number;
}
export interface IFunnelState {
  questions: Question[] | [];
  products: Product[] | [];
  experiments: Experiments | null;
}
