import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Option } from 'state/funnel/types';
import { useQuery } from 'styles/breakpoints';
import { FlexWrapper, OptionCard, OutlineButton } from 'components';

import { QuizCallbacks } from '../sections/AskQuestion';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';

export const MultipleOption: React.FC<QuizCallbacks> = ({
  onSelect,
  question,
}) => {
  const { isMobile } = useQuery();
  const { quiz_answers } = useSelector((s: AppState) => s.user);
  const isBig = !!question?.custom?.isBig;
  const options = question.options || [];
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<string, boolean>
  >({});
  const disabled = Object.keys(selectedAnswers).length === 0;
  const handleOptionClick = (option: Option) => () => {
    let newSelected = { ...selectedAnswers };

    if (selectedAnswers[option.value]) {
      delete newSelected[option.value];
    } else if (option?.custom?.deselectAll) {
      newSelected = { [option.value]: true };
    } else {
      const deselector = options.find(option => option.custom?.deselectAll)
        ?.value;

      newSelected = { ...newSelected, [option.value]: true };

      if (deselector) delete newSelected[deselector];
    }

    setSelectedAnswers(newSelected);
  };
  const onSubmit = () => {
    if (!disabled) {
      const answer = Object.keys(selectedAnswers).join('|');

      if (question.key) {
        onSelect({ [question.key]: answer, label: answer });
      }
    }
  };

  return (
    <Container isBig={isBig}>
      <OptionsWrapper
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        {options.map((option: Option, index: number) => (
          <OptionCard
            key={index}
            {...option}
            isBig={isBig}
            isMultiple={true}
            isSelected={!!selectedAnswers[option.value]}
            onClick={handleOptionClick(option)}
          />
        ))}
      </OptionsWrapper>
      <OutlineButton
        maxWidth={isMobile ? '100%' : '11.125rem'}
        margin={isMobile ? '2rem auto 1.375rem' : '0 auto 1.375rem'}
        disabled={disabled}
        onClick={onSubmit}
      >
        NEXT
      </OutlineButton>
    </Container>
  );
};

const Container = styled.div<{ isBig?: boolean }>`
  max-width: ${props => (props.isBig ? 76.125 : 37.125)}rem;
  width: 100%;
  margin: 0 auto;
`;
const OptionsWrapper = styled(FlexWrapper)`
  margin: 0 auto 1rem auto;
`;
